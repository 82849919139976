import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";
import Countdown from "react-countdown";
import Renderer from "../components/countdown/Renderer";
import SubscribeEmail from "../components/newsletter/SubscribeEmail";

const ComingSoon14 = () => {
  return (
    <Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Roboto:wght@400;500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div
        className="cs-14-page-wrapper h-100 bg-img d-flex flex-column justify-content-between"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL +
            "/assets/img/backgrounds/BGtragos.jpg"
          })`
        }}
      >
        {/*====================  header ====================*/}
        <header className="cs-14-header space-pt--30 space-pb--30">
          <div className="container">
            <div className="row">
              <div className="col-4">
                {/* logo */}
               {/*  <div className="cs-14-logo">
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/logo/gemetlogoBlanco.svg"
                      }
                      className="img-fluid"
                      alt=""
                      width={240}
                    />
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </header>
        {/*====================  End of header  ====================*/}
        {/*====================  content ====================*/}
        <div className="cs-14-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
               {/*  <h3 className="cs-14-content__subtitle space-mb--10">
                  Página en Construcción
                </h3> */}
                <h2 className="cs-14-content__title">
                  akgin.cl <br /> Página en Construcción
                </h2>
                {/* countdown */}
                <div className="cs-14-countdown space-mt--50">
                  <Countdown
                    date={new Date("December 30, 2024 12:12:00")}
                    renderer={Renderer}
                  />
                </div>
                <div className="cs-14-subscription-wrapper space-mt--50">
                  {/* subscribe email */}
                  {/* <SubscribeEmail mailchimpUrl="https://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" /> */}
                </div>
              </div>

              <div className="col-lg-4">
              {/* <div className="contact-from-all">
                  <div className="hadding2">
                      <h4 className="font-f-2 weight-700 font-24 font-lg-24 line-height-lg-45">Suscríbete y comienza a ganar</h4>
                      <div className="space24" />
                      <p className="font-16 line-height-18 font-f-2 weight-400 line-height-300">Te presentamos Gemet, el casino online que combina tecnología de vanguardia, seguridad, y una experiencia de usuario inigualable.</p>
                  </div>
                  <div className="space10" />
                  <form action="#">
                      <div className="contact-page-form">
                          <div className="forom-input">
                              <input type="text" placeholder="Nombre" />
                              <input type="text" placeholder="Apellido" />
                          </div>
                          <div className="forom-input">
                              <input type="email" placeholder="Email" />
                              <input type="text" placeholder="RUT" />
                          </div>
                          <div className="forom-input">
                              <input type="text" placeholder="Telefóno" />
                              <input type="text" placeholder="Numero de Cuenta" />
                          </div>
                          <div className="forom-input">
                              <input type="number" placeholder="Telefóno" />
                              <select className="wide">
                                  <option value>Service</option>
                                  <option value>Service 2</option>
                                  <option value>Service 3</option>
                                  <option value>Service 4</option>
                              </select>
                          </div>
                          <div className="forom-input">
                              <textarea cols={30} rows={2} placeholder="Your Message" />
                          </div>
                          <div className="space10" />
                          <div className="form-check form-check-inline input-chack-box">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                              <label className="form-check-label font-f-2" htmlFor="inlineCheckbox2">Acepto los <span>términos,</span>  <span>condiciones</span> y <span>política de privacidad</span></label>
                          </div>
                          <div className="contact-form-btn">
                              <button>Enviar Ahora</button>
                          </div>
                      </div>
                  </form>
              </div> */}              
              </div>

            </div>
          </div>
        </div>
        {/*====================  End of content  ====================*/}
        {/*====================  footer ====================*/}
        <footer className="cs-14-footer space-pt--25 space-pb--25">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                {/* social icons */}
                <ul className="cs-14-social-icons d-flex align-items-center justify-content-center justify-content-md-start">
                  <li>
                    <a
                      href="http://www.facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/facebook-01.svg"
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/twitter-01.svg"
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/instagram-01.svg"
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/linkedin-01.svg"
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.youtube.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          '/"assets/img/icons/youtube-01.svg'
                        }
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                {/* copyright */}
                <div className="cs-14-copyright text-right">
                  &copy; {new Date().getFullYear() + " "}
                  <a
                    href="https://hasthemes.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    akgin.cl
                  </a>
                  , Todos los derechos reservados
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====================  End of footer  ====================*/}
      </div>
    </Fragment>
  );
};

export default ComingSoon14;
